import React from 'react';
import Popup from '../../Popup/Popup.tsx';
import './changeWallet.scss';

const ChangeWalletPopup = () => {
  return (
    <Popup modalID="changeWallet">
      <div className="ChangeWallet">
        <p>В настоящее время доступен только один кошелек для разблокировки:</p>
        <ul>
          <li
            className="active"
            // onClick={() => setShowChangeWalletPopup(false)}
          >
            Текущий
            <span className="sub_title">
              BTC address: 18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB
            </span>
          </li>
        </ul>
      </div>
    </Popup>
  );
};

export default ChangeWalletPopup;
